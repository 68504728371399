import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Swiper } from "swiper/react";

import IconArrowNext from "./icon-arrow-next.svg?url";
import IconArrowPrevious from "./icon-arrow-previous.svg?url";

export const StyledSwiper = styled(Swiper)`
  ${setCustomNavigationStyle()}
  ${setCustomPaginationStyle()}
`;

function setCustomNavigationStyle() {
  return css`
    & {
      &:hover {
        .swiper-button-prev,
        .swiper-button-next {
          opacity: 1;
        }
      }
      .swiper-button-prev {
        background-image: url(${IconArrowPrevious});
      }
      .swiper-button-next {
        background-image: url(${IconArrowNext});
      }
      .swiper-button-prev,
      .swiper-button-next {
        inline-size: 40px;
        block-size: 40px;

        border-radius: 50%;

        background-color: #4585ff;
        background-repeat: no-repeat;
        background-position: center;

        box-shadow:
          0 2px 4px 0 rgba(0, 0, 0, 0.14),
          0 3px 4px 0 rgba(0, 0, 0, 0.12),
          0 1px 5px 0 rgba(0, 0, 0, 0.2);

        opacity: 0;

        transition: opacity 1s;

        &::after {
          content: unset;
        }

        &.swiper-button-disabled {
          opacity: 0;
        }
      }
    }
  `;
}

function setCustomPaginationStyle() {
  return css`
    & {
      --swiper-pagination-bullet-size: 4px;
      --swiper-pagination-color: #000000;

      .swiper-pagination {
        position: static;

        padding-block: 8px;
      }
    }
  `;
}
