/**
 * @generated SignedSource<<49ff31fa3a81af2a12adb01dae9fbf5b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type entityFollowButtonBase$data = {
  readonly __typename: "Author";
  readonly disableFollow: boolean | null | undefined;
  readonly entityId: string;
  readonly name: string;
  readonly " $fragmentType": "entityFollowButtonBase";
} | {
  readonly __typename: "Section";
  readonly entityId: string;
  readonly name: string;
  readonly " $fragmentType": "entityFollowButtonBase";
} | {
  readonly __typename: "Topic";
  readonly disableFollow: boolean | null | undefined;
  readonly entityId: string;
  readonly name: string;
  readonly " $fragmentType": "entityFollowButtonBase";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "entityFollowButtonBase";
};
export type entityFollowButtonBase$key = {
  readonly " $data"?: entityFollowButtonBase$data;
  readonly " $fragmentSpreads": FragmentRefs<"entityFollowButtonBase">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "disableFollow",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "entityFollowButtonBase",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "type": "Section",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v2/*: any*/),
      "type": "Author",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v2/*: any*/),
      "type": "Topic",
      "abstractKey": null
    }
  ],
  "type": "BaseWithApplicationAndUrlAlias",
  "abstractKey": "__isBaseWithApplicationAndUrlAlias"
};
})();

(node as any).hash = "6e574c1387e3f136897b15a3b98d5016";

export default node;
