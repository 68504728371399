import type { FunctionComponent } from "react";

import { config } from "scmp-app/data";

import { Container, StyledLogoFlag, SubscribeNowButton, TrialText } from "./styles";

export type Props = {
  className?: string;
  onClick?: () => void;
};

export const SubscriptionWidget: FunctionComponent<Props> = ({ className, onClick }) => (
  <Container className={className}>
    <StyledLogoFlag />
    <TrialText>Help preserve 120 years of quality journalism.</TrialText>
    <SubscribeNowButton
      anchorProps={{ target: "_blank" }}
      onClick={onClick}
      pathname={config.rosetta.subscribeHost}
    >
      SUPPORT NOW
    </SubscribeNowButton>
  </Container>
);

SubscriptionWidget.displayName = "SubscriptionWidget";
