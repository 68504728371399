/**
 * @generated SignedSource<<cf1e3186babb2a6d44ff52389c9eb69a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type followButtonBase$data = {
  readonly entityId: string;
  readonly " $fragmentSpreads": FragmentRefs<"entityFollowButtonBase">;
  readonly " $fragmentType": "followButtonBase";
};
export type followButtonBase$key = {
  readonly " $data"?: followButtonBase$data;
  readonly " $fragmentSpreads": FragmentRefs<"followButtonBase">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "followButtonBase",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "entityFollowButtonBase"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    }
  ],
  "type": "BaseWithApplicationAndUrlAlias",
  "abstractKey": "__isBaseWithApplicationAndUrlAlias"
};

(node as any).hash = "85a5ad255445b3cc09bfad4b7227f16e";

export default node;
