/* cspell: ignore liga */

import styled from "@emotion/styled";
import { fontMerriweather, fontRobotoCondensed } from "@product/scmp-sdk";

import { BaseLink } from "scmp-app/components/common/base-link";

import LogoFlag from "./icon-logo-flag.svg";

export const StyledLogoFlag = styled(LogoFlag)`
  position: absolute;
  inset-block-start: -13.5px;
  inset-inline: 0;

  inline-size: 35px;
  block-size: 27px;
  margin-inline: auto;

  ${props => props.theme.breakpoints.up("tablet")} {
    position: unset;

    inline-size: 60px;
    block-size: 46px;
    margin-inline: unset;
  }
`;

export const Container = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: space-between;
  align-items: center;

  padding-block: 25.5px 30.5px;
  padding-inline: 20px;

  border: 2px solid #ffca05;

  ${props => props.theme.breakpoints.up("tablet")} {
    flex-direction: row;
    gap: 0;

    padding-block: 20px;
    padding-inline: 24px;
  }
`;

export const SubscribeNowButton = styled(BaseLink)`
  position: absolute;
  inset-block-end: -16px;
  inset-inline: 0;

  inline-size: fit-content;
  margin-inline: auto;
  padding-block: 8px;
  padding-inline: 12px;

  color: #ffffff;
  font-weight: 400;
  font-size: 18px;
  font-family: ${fontRobotoCondensed};
  white-space: nowrap;
  text-transform: uppercase;

  border-radius: 4px;

  background-color: #4585ff;

  ${props => props.theme.breakpoints.up("tablet")} {
    position: unset;

    margin-inline: unset;
    margin-inline-start: auto;

    font-size: 16px;
  }
`;

export const OfferText = styled.div`
  flex: 1;

  font-weight: 400;
  font-size: 16px;
  font-family: ${fontRobotoCondensed};
  white-space: nowrap;
  text-align: center;
  font-feature-settings:
    "clig" off,
    "liga" off;

  ${props => props.theme.breakpoints.up("tablet")} {
    margin-inline: 32px;
  }
`;

export const TrialText = styled.div`
  color: #000000;
  font-weight: 400;
  font-size: 20px;
  font-family: ${fontMerriweather};
  line-height: 25px;
  text-align: center;

  font-feature-settings:
    "clig" off,
    "liga" off;

  ${props => props.theme.breakpoints.up("tablet")} {
    margin-inline-start: 28px;

    text-align: start;
  }
`;

export const PriceText = styled.span`
  font-weight: 700;
`;
