import { useDeepCompareEffect } from "@react-hookz/web";
import { useSetAtom } from "jotai";

import type { AdSlotState } from "./atoms";
import { topBannerAdSlotAtom } from "./atoms";

export const useTopBannerAdSlot = (state: AdSlotState) => {
  const setState = useSetAtom(topBannerAdSlotAtom);
  useDeepCompareEffect(() => {
    setState(state);
    return () => setState(null);
  }, [state, setState]);
};
