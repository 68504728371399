import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { checkHasLiveTag } from "scmp-app/components/article/article-render/helpers";
import { withHighlightedHeadline } from "scmp-app/components/content/content-item-render/highlight/hoc";
import { LinkHighlightArea } from "scmp-app/components/content/content-item-render/highlight/styles";
import { useContentItemProviders } from "scmp-app/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "scmp-app/components/content/content-item-render/types";
import type { homePrimaryContentItemContent$key } from "scmp-app/queries/__generated__/homePrimaryContentItemContent.graphql";

import {
  ActionBar,
  Container,
  Content,
  CoverImage,
  Header,
  Headline,
  StyledEntityLink,
  Summary,
} from "./styles";

export type Props = {
  reference: homePrimaryContentItemContent$key;
  withCaption?: boolean;
  withComment?: boolean;
  withLiveTag?: boolean;
  withSection?: boolean;
} & ContentItemRenderVariantProps;

const Component = forwardRef<HTMLDivElement, Props>(
  (
    {
      className,
      onClick,
      reference: reference_,
      withCaption = true,
      withComment = false,
      withLiveTag = false,
      withSection = true,
    },
    reference,
  ) => {
    const content = useFragment(
      graphql`
        fragment homePrimaryContentItemContent on Content {
          entityId
          urlAlias
          ...entityLink
          ...helpersCheckHasLiveTagArticle
          ...hooksContentItemProvidersContent
            @arguments(
              withSections: true
              withHeadline: true
              withLiveTag: true
              withSummary: true
              withActionBar: true
              withCoverImage: true
              withCoverImageSize1200x800: true
              withCoverImageSize540x360: true
              withVideoDurationOverlay: true
            )
        }
      `,
      reference_,
    );

    const providers = useContentItemProviders(content);
    const hasLiveTag = checkHasLiveTag(content);
    const isShowLiveTag = hasLiveTag && withLiveTag;

    return (
      <Container className={className} ref={reference}>
        <Content>
          {(isShowLiveTag || withSection) && (
            <Header>
              {providers.liveTag({ hide: !isShowLiveTag })}
              {providers.sections({ hide: isShowLiveTag })}
            </Header>
          )}
          <StyledEntityLink
            onClick={() => onClick?.(content.entityId, content.urlAlias)}
            reference={content}
          >
            <LinkHighlightArea>
              <Headline>
                {providers.headline({ hideTooltip: true, preferSocialHeadline: true })}
              </Headline>
              <Summary>
                {providers.summary({
                  isTextOverflowEllipsis: true,
                })}
              </Summary>
            </LinkHighlightArea>
          </StyledEntityLink>
          <ActionBar>
            {providers.actionBar({
              commentVariant: "reverse",
              isBookmarkVisible: false,
              isCommentsVisible: withComment ?? false,
            })}
          </ActionBar>
        </Content>
        <CoverImage>
          <StyledEntityLink
            onClick={() => onClick?.(content.entityId, content.urlAlias)}
            reference={content}
          >
            {providers.coverImage({
              responsiveVariants: {
                desktopUp: "size1200x800",
                mobileUp: "size540x360",
                tabletUp: "size1200x800",
              },
              withCaption,
            })}
            {providers.video?.durationOverlay({
              iconSize: "large",
              variant: "homepage",
            })}
          </StyledEntityLink>
        </CoverImage>
      </Container>
    );
  },
);

Component.displayName = "ContentItemHomePrimary";

export const ContentItemHomePrimary = withHighlightedHeadline(Component);
